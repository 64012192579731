import {prod} from './global.js';
(function () {
    // let cors = require('cors')
    // app.use(cors())
    const loginButton = document.getElementById('login');
    const notificationDivEl = document.getElementById('notification');
    const inputs = Array.from(document.querySelectorAll('input'));

    loginButton.addEventListener('click', login);
    inputs[0].addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
            login()
        }
    });
    inputs[1].addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {  //checks whether the pressed key is "Enter"
            login()
        }
    });


    function login() {
        //const inputs = Array.from(document.querySelectorAll('input'));
        const values = inputs.map(i => i.value);

        // const data = {
        //     "email": values[0],
        //     "pin": values[1]
        // }

        const url =  prod + `/api/m/customers/login?email=${values[0]}&pin=${values[1]}&partner=1`



        fetch(url, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },

        }).then(res => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 500) {
                notificationDivEl.textContent = 'Грешен email или парола!';
                notificationDivEl.style.display = 'block';
                notificationDivEl.classList.add('error');

                setTimeout(function () {
                    notificationDivEl.textContent = '';
                    notificationDivEl.style.display = 'none';
                    notificationDivEl.classList.remove('error');
                }, 3000)
                // alert('Грешен email или парола!');
                return;
            }
        })
            .then(data => {
                if (!data) {
                    return;
                }
                sessionStorage.setItem('customerId', data.customerId);
                sessionStorage.setItem('email', data.email);
                // console.log(data)
                //getUser(data.customerId)
                const creds = data.email + '/1:' + values[1];
                const token = btoa(creds);
                sessionStorage.setItem('token', token);
                //console.log(token);
                const reqUrl =  prod + `/api/m/customers/${data.customerId}`;

                fetch(reqUrl, {
                    method: 'GET',
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Basic ${token}`
                    }
                })
                    .then(res => res.json())
                    .then(data => {
                        sessionStorage.setItem('username', data.firstName)
                        sessionStorage.setItem('phoneNumber', data.phoneNumber)
                        location.href = "/home.html";
                    })
                //location.href = "/home.html";
            })

        // function getUser(id, token) {
        //     const reqUrl =  prod + `/api/m/customers/${id}`;

        //     fetch(reqUrl, {
        //         method: 'GET',
        //         headers: {
        //             "Accept": "application/json",
        //             "Content-Type": "application/json",
        //             Authorization: `Basic ${token}`
        //         }
        //     })
        //     .then(res => res.json())
        //     .then(data => {
        //         console.log(data)
        //         //location.href = "/home.html";
        //     })
        // }



        // location.href = "/home.html";
    }
}());